import React, { Suspense, useLayoutEffect, useState } from 'react';
import { Spring } from 'react-spring/renderprops';
import { Canvas, useLoader, Vector3 } from 'react-three-fiber';
import { TextureLoader } from 'three';
import leftArrow from './assets/left-arrow.svg';
import rightArrow from './assets/right-arrow.svg';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import PageControl from './PageControl';

function clamp(v: number, lo: number, hi: number) {
  return Math.min(Math.max(v, lo), hi);
}

function computeCirclePosition(radius: number, angle: number): Vector3 {
  return [
    radius * Math.cos(angle) * 2,
    0,
    -radius * Math.sin(angle) - radius - 10,
  ];
}

function computeCirclePositions(
  radius: number,
  count: number,
  offset: number
): Array<Vector3> {
  let angle = (3 * Math.PI) / 2 + offset;
  let retval = [];
  for (let i = 0; i < count; i++) {
    retval.push(computeCirclePosition(radius, angle));
    angle = (angle + (Math.PI * 2) / count) % (Math.PI * 2);
  }
  return retval;
}

function Slide(props: {
  image: string,
  position: Vector3,
  setHovering: (hovering: boolean) => void,
  onClick: () => void,
}) {
  const [width] = useWindowSize();
  const texture = useLoader(TextureLoader, `/${props.image}`);
  return (
    <mesh
      position={props.position}
      onPointerOver={() => props.setHovering(true)}
      onPointerOut={() => props.setHovering(false)}
      onPointerUp={props.onClick}
    >
      <planeGeometry
        args={[
          width < 700 ? 2 : 4,
          ((width < 700 ? 2 : 4) * texture.image.height) / texture.image.width,
        ]}
      />
      <meshBasicMaterial attach="material" map={texture} transparent />
    </mesh>
  );
}

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const ArrowButtonBackground = styled.div`
  width: 2em;
  height: 2em;
  background-color: #e9e9e9;
  border-radius: 1em;
  cursor: pointer;
  user-select: none;
  &:hover {
    background-color: #bdbdbd;
  }
`;

export function ArrowButton(props: {
  src: string,
  alt: string,
  onClick: () => void,
}) {
  return (
    <ArrowButtonBackground onClick={props.onClick}>
      <img
        style={{ width: '0.5em', height: '1em', padding: '0.5em 0.75em' }}
        src={props.src}
        alt={props.alt}
      />
    </ArrowButtonBackground>
  );
}

interface CarouselProps {
  slides: Array<{ title: string, image: string }>;
}

export default function Carousel(props: CarouselProps) {
  const [width] = useWindowSize();
  const [lastDragPosition, setLastDragPosition] = useState<
    [number, number] | null
  >(null);
  const [dragOffset, setDragOffset] = useState(0);
  const [dragDistance, setDragDistance] = useState(0);
  const [lastCommittedSlideIdx, setLastCommittedSlideIdx] = useState(0);
  const [hoveringSlideIdx, setHoveringSlideIdx] = useState<number | null>(null);
  const [pendingRedirect, setPendingRedirect] = useState<string | null>(null);
  const history = useHistory();

  return (
    <Spring
      from={{ dragOffset: 0 }}
      to={{ dragOffset }}
      onRest={({ dragOffset: value }) => {
        if (pendingRedirect) {
          setPendingRedirect(null);
          history.push(pendingRedirect);
        }
      }}
    >
      {({ dragOffset: animatedDragOffset }) => {
        const selectedSlideIdx =
          animatedDragOffset > 0
            ? Math.floor(
                (animatedDragOffset + (Math.PI * 2) / props.slides.length / 2) /
                  ((Math.PI * 2) / props.slides.length)
              )
            : Math.ceil(
                (animatedDragOffset - (Math.PI * 2) / props.slides.length / 2) /
                  ((Math.PI * 2) / props.slides.length)
              ); // good luck

        const normalizedSelectedSlideIdx =
          ((-selectedSlideIdx % props.slides.length) + props.slides.length) %
          props.slides.length;
        const currTitle = props.slides[normalizedSelectedSlideIdx].title;
        const positions = computeCirclePositions(
          width < 700 ? 2 : 5,
          props.slides.length,
          animatedDragOffset
        );

        const resetPosition = () => {
          setLastDragPosition(null);
          let newSlideIdx = selectedSlideIdx;
          if (newSlideIdx === lastCommittedSlideIdx) {
            if (
              animatedDragOffset - (Math.PI * 2) / props.slides.length / 16 >
              (newSlideIdx * Math.PI * 2) / props.slides.length
            ) {
              newSlideIdx += 1;
            } else if (
              animatedDragOffset + (Math.PI * 2) / props.slides.length / 16 <
              (newSlideIdx * Math.PI * 2) / props.slides.length
            ) {
              newSlideIdx -= 1;
            }
          }
          setLastCommittedSlideIdx(newSlideIdx);
          setDragOffset((newSlideIdx * Math.PI * 2) / props.slides.length);
        };

        // In [0, 2pi)
        const normalizedSlidePosition =
          ((animatedDragOffset % (Math.PI * 2)) + Math.PI * 2) % (Math.PI * 2);

        // In [0, 1)
        const snapPointDistance = Math.abs(
          (((normalizedSlidePosition +
            (Math.PI * 2) / props.slides.length / 2) %
            ((Math.PI * 2) / props.slides.length)) /
            ((Math.PI * 2) / props.slides.length) -
            0.5) *
            2
        );

        const heightPercent = width < 700 ? 60 : 70;

        return (
          <div
            style={{
              position: 'fixed',
              top: '60px',
              left: 0,
              width: '100%',
              height: `${heightPercent}%`,
              cursor:
                hoveringSlideIdx !== null
                  ? 'pointer'
                  : "url('/cursor.png'), auto",
            }}
          >
            <h1
              key={currTitle}
              style={{
                opacity: (1 - snapPointDistance) * (1 - snapPointDistance),
                userSelect: 'none',
                fontWeight: 500,
                fontSize: '32px',
              }}
            >
              {currTitle}
            </h1>
            {/* <div
              style={{
                position: 'absolute',
                top: `calc(${(50 / heightPercent) * 100}% - 1em)`,
                left: '3em',
                zIndex: 5,
              }}
            >
              <ArrowButton
                src={leftArrow}
                alt="left arrow"
                onClick={() => {
                  setLastDragPosition(null);
                  setDragDistance(0);
                  const newSlideIdx = lastCommittedSlideIdx + 1;
                  setLastCommittedSlideIdx(newSlideIdx);
                  setDragOffset(
                    (newSlideIdx * Math.PI * 2) / props.slides.length
                  );
                }}
              />
            </div>

            <div
              style={{
                position: 'absolute',
                top: `calc(${(50 / heightPercent) * 100}% - 1em)`,
                right: '3em',
                zIndex: 5,
              }}
            >
              <ArrowButton
                src={rightArrow}
                alt="right arrow"
                onClick={() => {
                  setLastDragPosition(null);
                  setDragDistance(0);
                  const newSlideIdx = lastCommittedSlideIdx - 1;
                  setLastCommittedSlideIdx(newSlideIdx);
                  setDragOffset(
                    (newSlideIdx * Math.PI * 2) / props.slides.length
                  );
                }}
              />
            </div> */}

            <Canvas
              onMouseDown={(ev) => {
                setLastDragPosition([
                  ev.nativeEvent.offsetX,
                  ev.nativeEvent.offsetY,
                ]);
                setDragDistance(0);
              }}
              onMouseUp={resetPosition}
              onMouseMove={(ev) => {
                if (lastDragPosition) {
                  setDragOffset(
                    animatedDragOffset +
                      ((ev.nativeEvent.offsetX - lastDragPosition[0]) / width) *
                        Math.PI *
                        10
                  );
                  setLastDragPosition([
                    ev.nativeEvent.offsetX,
                    ev.nativeEvent.offsetY,
                  ]);
                  setDragDistance(
                    dragDistance +
                      ((ev.nativeEvent.offsetX - lastDragPosition[0]) / width) *
                        Math.PI *
                        10
                  );
                }
              }}
              onMouseLeave={resetPosition}
              onTouchStart={(ev) => {
                setLastDragPosition([
                  ev.touches[0].clientX,
                  ev.touches[0].clientY,
                ]);
                setDragDistance(0);
              }}
              onTouchEnd={resetPosition}
              onTouchCancel={resetPosition}
              onTouchMove={(ev) => {
                if (lastDragPosition) {
                  setDragOffset(
                    animatedDragOffset +
                      ((ev.touches[0].clientX - lastDragPosition[0]) / width) *
                        Math.PI *
                        10
                  );
                  setLastDragPosition([
                    ev.touches[0].clientX,
                    ev.touches[0].clientY,
                  ]);
                  setDragDistance(
                    dragDistance +
                      ((ev.touches[0].clientX - lastDragPosition[0]) / width) *
                        Math.PI *
                        10
                  );
                }
              }}
              camera={{ position: [0, 0, 0] }}
              pixelRatio={window.devicePixelRatio}
            >
              <planeBufferGeometry args={[2, 2]} />
              {props.slides.map((s, idx) => (
                <Suspense fallback={null} key={idx}>
                  <Slide
                    image={s.image}
                    position={positions[idx]}
                    setHovering={(hovering) =>
                      setHoveringSlideIdx(hovering ? idx : null)
                    }
                    onClick={() => {
                      setTimeout(() => {
                        if (
                          Math.abs(dragDistance) >
                          (Math.PI * 2) / props.slides.length / 16
                        ) {
                          return;
                        }

                        if (idx === normalizedSelectedSlideIdx) {
                          history.push(`/${idx}`);
                          return;
                        }

                        const rightDistance =
                          (idx -
                            normalizedSelectedSlideIdx +
                            props.slides.length) %
                          props.slides.length;
                        setLastDragPosition(null);
                        if (rightDistance > props.slides.length / 2) {
                          let newSlideIdx =
                            selectedSlideIdx +
                            (props.slides.length - rightDistance);
                          setLastCommittedSlideIdx(newSlideIdx);
                          setDragOffset(
                            (newSlideIdx * Math.PI * 2) / props.slides.length
                          );
                        } else {
                          let newSlideIdx = selectedSlideIdx - rightDistance;
                          setLastCommittedSlideIdx(newSlideIdx);
                          setDragOffset(
                            (newSlideIdx * Math.PI * 2) / props.slides.length
                          );
                        }

                        setPendingRedirect(`/${idx}`);
                      }, 1);
                    }}
                  />
                </Suspense>
              ))}
            </Canvas>
            <PageControl
              style={{ width: '100%' }}
              opacities={[...Array(props.slides.length)].map((_, idx) => {
                return (
                  clamp(
                    1 -
                      Math.abs(
                        normalizedSlidePosition -
                          ((props.slides.length - idx) * Math.PI * 2) /
                            props.slides.length
                      ),
                    0,
                    1
                  ) +
                  clamp(
                    1 -
                      Math.abs(
                        normalizedSlidePosition +
                          Math.PI * 2 -
                          ((props.slides.length - idx) * Math.PI * 2) /
                            props.slides.length
                      ),
                    0,
                    1
                  )
                );
              })}
            />
          </div>
        );
      }}
    </Spring>
  );
}
