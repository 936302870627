import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const PasscodeDot =
  styled.div <
  { filled: boolean } >
  `
  width: 12px;
  height: 12px;
  background-color: ${(props) => (props.filled ? '#737373' : '#c4c4c4')};
  border-radius: 6px;
  margin-right: 40px;

  &:first-child {
    margin-left: 40px;
  }
`;

const PasscodeBox = styled.div`
  width: 260px;
  height: 60px;
  border-radius: 16px;
  background-color: #ebebeb66;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export default function EnterPasscode({ onPass }: { onPass: () => void }) {
  let [passcode, setPasscode] = useState('');

  useEffect(() => {
    function handle(event: KeyboardEvent) {
      if (event.key === 'Backspace') {
        setPasscode((passcode) => passcode.slice(0, -1));
      } else {
        if (event.key >= '0' && event.key <= '9') {
          setPasscode((passcode) => passcode + event.key);
        }
      }
    }

    window.addEventListener('keydown', handle);
    return () => window.removeEventListener('keydown', handle);
  }, []);

  useEffect(() => {
    if (passcode.length === 4 && passcode !== '1234') {
      setPasscode('');
    } else if (passcode === '1234') {
      onPass();
    }
  }, [passcode, onPass]);

  return (
    <div
      style={{
        position: 'fixed',
        top: '60px',
        left: 0,
        width: '100%',
        height: '100vh',
      }}
    >
      <h1
        style={{
          fontWeight: 500,
          fontSize: '32px',
        }}
      >
        Enter Passcode
      </h1>

      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      >
        <PasscodeBox>
          {[...Array(4)].map((_, idx) => (
            <PasscodeDot filled={passcode.length > idx} key={idx} />
          ))}
        </PasscodeBox>
      </div>
    </div>
  );
}
