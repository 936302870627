import React from 'react';
import { ArrowButton, useWindowSize } from './Carousel';
import leftArrow from './assets/left-arrow.svg';
import close from './assets/close.svg';
import { useHistory } from 'react-router-dom';

export default function Modal({
  title,
  body,
}: {
  title: string,
  body: string,
}) {
  const history = useHistory();
  const [width] = useWindowSize();
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 10,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'white',
        padding: '100px',
        textAlign: 'left',
        color: '#888888',
      }}
    >
      <div
        style={{
          position: 'fixed',
          top: '36px',
          right: '36px',
          width: '20px',
          height: '20px',
          cursor: 'pointer',
        }}
        onClick={() => history.push('/')}
      >
        <img src={close} alt="close" />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: 'calc(50% - 150px)',
          fontSize: '16px',
          lineHeight: '32px',
          marginRight: '100px',
          height: 'calc(100vh - 200px)',
        }}
      >
        {/* Left column */}
        <div style={{ minHeight: '496px' }}>
          <strong>Kernel</strong>
          <br />
          Kernel builds two devices. Kernel Flow and Kernel Flux. Both are brain
          imaging technologies.
        </div>
        <div
          style={{
            borderTopColor: 'rgba(0, 0, 0, 0.05)',
            borderTopWidth: 2,
            borderTopStyle: 'solid',
            paddingTop: '30px',
          }}
        >
          <strong>Contact</strong>
          <br />
          ryan@metzger.ventures
          <br />
          +1 646 934 2050
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: 'calc(50% - 150px)',
        }}
      >
        {/* Right column */}
        <div style={{ height: '132px' }}>
          <strong>SPV Details</strong>
        </div>
        <div
          style={{
            borderTopColor: 'rgba(0, 0, 0, 0.05)',
            borderTopWidth: 2,
            borderTopStyle: 'solid',
            paddingTop: '30px',
            paddingBottom: '80px',
          }}
        >
          <p
            style={{
              fontSize: '40px',
              margin: 0,
              padding: 0,
              lineHeight: '32px',
            }}
          >
            $35,000,000
          </p>
          <br />
          Allocation
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: '80px',
          }}
        >
          <div
            style={{
              width: 'calc(50% - 10px)',
              borderTopColor: 'rgba(0, 0, 0, 0.05)',
              borderTopWidth: 2,
              borderTopStyle: 'solid',
              paddingTop: '30px',
              marginRight: '20px',
            }}
          >
            <p
              style={{
                fontSize: '40px',
                margin: 0,
                padding: 0,
                lineHeight: '32px',
              }}
            >
              5,000,000
            </p>
            <br />
            Minimum Ticket
          </div>
          <div
            style={{
              width: 'calc(50% - 10px)',
              borderTopColor: 'rgba(0, 0, 0, 0.05)',
              borderTopWidth: 2,
              borderTopStyle: 'solid',
              paddingTop: '30px',
            }}
          >
            <p
              style={{
                fontSize: '40px',
                margin: 0,
                padding: 0,
                lineHeight: '32px',
              }}
            >
              2%
            </p>
            <br />
            Management Fee
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: '80px',
          }}
        >
          <div
            style={{
              width: 'calc(50% - 10px)',
              borderTopColor: 'rgba(0, 0, 0, 0.05)',
              borderTopWidth: 2,
              borderTopStyle: 'solid',
              paddingTop: '30px',
              marginRight: '20px',
            }}
          >
            <p
              style={{
                fontSize: '40px',
                margin: 0,
                padding: 0,
                lineHeight: '32px',
              }}
            >
              20%
            </p>
            <br />
            Carry
          </div>
          <div
            style={{
              width: 'calc(50% - 10px)',
              borderTopColor: 'rgba(0, 0, 0, 0.05)',
              borderTopWidth: 2,
              borderTopStyle: 'solid',
              paddingTop: '30px',
            }}
          >
            <p
              style={{
                fontSize: '40px',
                margin: 0,
                padding: 0,
                lineHeight: '32px',
              }}
            >
              Legals
            </p>
            <br />
            Additional Fees
          </div>
        </div>
      </div>
    </div>
  );
}
