import React from 'react';
import keyboard from './assets/keyboard.png';

export default function DesktopOnly() {
  return (
    <div
      style={{
        position: 'fixed',
        top: '60px',
        left: 0,
        width: '100%',
        height: '100vh',
      }}
    >
      <h1
        style={{
          fontWeight: 500,
          fontSize: '32px',
        }}
      >
        Desktop Only
      </h1>

      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      >
        <img
          style={{
            maxWidth: '786px',
            width: '50vw',
            margin: 'auto',
          }}
          src={keyboard}
          alt="keyboard"
        />
      </div>
    </div>
  );
}
