import React from 'react';

interface PageControlProps {
  opacities: number[];
  style?: React.CSSProperties;
}

function Dot({ opacity }: { opacity: number }) {
  return (
    <div
      style={{
        width: '6px',
        height: '6px',
        backgroundColor: 'black',
        borderRadius: '3px',
        opacity: opacity / 2 + 0.25,
        margin: '3px',
      }}
    ></div>
  );
}

export default function PageControl({ opacities, style }: PageControlProps) {
  return (
    <div
      style={{
        ...style,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      {opacities.map((opacity, idx) => (
        <Dot opacity={opacity} key={idx} />
      ))}
    </div>
  );
}
