import React, { Suspense, useState } from 'react';
import './App.css';
import Carousel, { useWindowSize } from './Carousel';
import DesktopOnly from './DesktopOnly';
import Modal from './Modal';
import {
  BrowserRouter as Router,
  useRouteMatch,
} from 'react-router-dom';
import data from './data.json';
import { animated, Transition } from 'react-spring/renderprops';
import EnterPasscode from './EnterPasscode';

function Content() {
  const match = useRouteMatch({ path: '/:company' });
  const [width] = useWindowSize();
  const [authenticated, setAuthenticated] = useState(false);

  if (width < 700) return <DesktopOnly />;

  return (
    <div>
      <p style={{ textAlign: 'center', fontWeight: 'normal', fontStyle: 'normal', paddingTop: 20, opacity: '32%', fontSize: '22px', lineHeight: '27px' }}>
        metzger ventures /
      </p>
      {!authenticated && <EnterPasscode onPass={() => setAuthenticated(true)} />}
      {authenticated && 
        <Transition
          items={match}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}
        >
          {(match) => (props) => {
            return (
              <animated.div
                style={{
                  ...props,
                  position: 'absolute',
                  zIndex: 100,
                }}
              >
                {match && <Modal title={data[parseInt((match!.params as any).company)].title!} body={data[parseInt((match!.params as any).company)].description} />}
              </animated.div>
            );
          }}
        </Transition>}
      {authenticated && <Carousel slides={data} />}
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Suspense fallback={<p>Loading...</p>}>
          <Content />
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
